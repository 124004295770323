import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { BaseUrl } from "../common/api";
import { withTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import ProductCardSkeleton from "../common/productSkeleton";
import { SecondaryButton } from "../assets/static/js/Button";
import { Suspense } from "react";


const cookies = new Cookies();
const SideBar = React.lazy(() => import("./sidebar"));
const ProductCard = React.lazy(() => import("../common/product-card"));
const Paginate = React.lazy(() => import("./paginate"));

class Collection extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables
    this.state = {

      nameUz: "",
      nameRu: "",
      limit: 21,
      offset: 0,
      total: 0,
      isLoading: true,
      notFound: false,
      filterShow: false,
      openfilter: true,
      button: false,
      collection: {
        products: [],
      },
      product: [],
      url: "",
      previousUrl: window.location.href
      
    };
    this._isMounted = false;
    this.parsed = {
      "sort-by": "all"
    }
    // detection language
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
    } else {
      this.name = "nameRu";
    }
    this.categorySlug = this.props.match.params.slug
  }
  async componentDidMount() {
    this._isMounted = true;
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    // opening or closing sidebar
    this.filterToggle();
    //  fetching data
    this.fetchData();

  }
  async componentDidUpdate(prevProps) {
    const currentUrl = window.location.href;
    this.pathname = this.props.location.pathname;

    // Проверяем, изменился ли URL
    if (this.state.previousUrl !== currentUrl) {
      console.log("URL has changed");
      // Скроллим страницу наверх и обновляем состояние
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
      
      this.setState(
        {
          previousUrl: currentUrl,
          isLoading: true,
          collection: {
            products: [],
          }
        },
        () => {
          this.fetchData(); // Вызов fetchData после обновления состояния
        }
      );
    }
  }

    


  // fetching data from api
  fetchData = async () => {

    // getting current url
    this.getQuery();
    const filterPath = this.state.previousUrl.split('/filter/')[1].split('?')[0];
    const filterObject = {};
    
    if (filterPath) {
      const filters = filterPath.split("/").filter(Boolean);
      
      filters.forEach((item) => {
        // Разделяем каждый элемент по "-or-" для получения нескольких значений
        const parts = item.split("-or-");
    
        parts.forEach(part => {
          // Разделяем на ключ и значения, убирая префикс ключа в значениях
          const [key, ...valueParts] = part.split("-");
          const value = valueParts.join("-");
    
          // Добавляем значения в filterObject
          if (filterObject[key]) {
            filterObject[key].push(value);
          } else {
            filterObject[key] = [value];
          }
        });
      });
    }
    
    // Генерация строки запроса из filterObject
    const queryUrl = Object.keys(filterObject)
      .map(key => `${key}=${filterObject[key].join(",")}`)
      .join("&") + `&${this.url}`
    
    console.log(`${BaseUrl}products/${this.categorySlug}?${queryUrl}`)
    const response = await axios.get(
      `${BaseUrl}products/${this.categorySlug}?${queryUrl}`
    );
    let product = response.data.results;        
    if (response.data.count === 0 || product.length === 0) {
      this._isMounted &&
        this.setState({
          notFound: true,
        });
    } else {
      const nameUz = product[0].collection.category_nameUz;
      const nameRu = product[0].collection.category_nameRu;
      this._isMounted &&
        this.setState({
          notFound: false,
          nameUz,
          nameRu,
        });
    }
    this._isMounted &&
      this.setState({
        product,
        total: response.data.count,
        isLoading: false,
      });
  };
  handleSortChange = (sort) => {
    this.parsed = this.queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    this.parsed["sort-by"] = sort;
    this.parsed.offset = 0
    this.url = this.queryString.stringify(this.parsed, {
      arrayFormat: "comma",
    });
    this.props.history.push(this.pathname + `?` + this.url);
    
  }
  // detec changes during pagination
  handlePageChange = (offset) => {
    if (this._isMounted) {
      this.setState({ offset }, this.fetchData);
  
      // Обновите `offset` в объекте `parsed`
      this.parsed.offset = offset;
  
      // Создайте строку запроса из параметров пагинации
      const paginationParams = this.queryString.stringify(
        { limit: this.parsed.limit, offset: this.parsed.offset },
        { skipNull: true, arrayFormat: "comma" }
      );
  
      // Получите baseUrl из текущего URL
      const baseUrl = window.location.pathname;
  
      // Формируем полный URL с сохранением фильтра и добавлением параметров пагинации
      this.url = `${baseUrl}?${paginationParams}`;
  
      // Обновляем URL в истории
      this.props.history.push(this.url);
    }
  };


  // getting current url and modify it
  getQuery = async () => {
    this.queryString = require("query-string");
    this.parsed = this.queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    this.category_id = this.parsed.category_id;
  
    if (this.parsed.offset) {
      this.offset = this.parsed.offset;
    } else {
      this.offset = this.state.offset;
    }
    if (this.parsed.limit) {
      this.url = this.queryString.stringify(this.parsed, {
        arrayFormat: "comma",
      });
    } else {
      this.url =
        this.queryString.stringify(this.parsed, {
          arrayFormat: "comma",
        }) + `&limit=${this.state.limit}`;
    }
  };
  // sending info to child component "Sidebar" it will work whenever width of window is lower than 576px
  filterToggle = () => {
    if (window.innerWidth < 576) {
      this._isMounted &&
        this.setState({ filterShow: false, openfilter: false, button: true });
    }
  };
  CloseFilter = () => {
    this._isMounted && this.setState({ filterShow: false, openfilter: false });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.filterToggle);
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;
    const { limit, total } = this.state;
    const  slug  = this.categorySlug

    return (
      <div className="main">
        {/* Suspens needs for lazyloading  */}
        <Suspense fallback={<div>Loading...</div>}>
          <div className="container">
            <div className="link-div">
              {/* home page redirect button */}
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                <path d="M8.5 15L1.5 8L8.5 1" stroke="#01091C"/>
              </svg>
              <p
                onClick={() => this.props.history.push("/")}
                className="text-sm pointer ml-2"
              >
                {t("mainPage.text")}
              </p>
              <span>&nbsp; / &nbsp;</span>
              {/* redirect to releted category page */}
              <p
                onClick={() =>
                  this.props.history.push(
                    `/category/${slug}/`
                  )
                }
                className="text-sm pointer"
              >
                {this.state[this.name]}
              </p>
              {/* <span>&nbsp; / &nbsp;</span> */}
              {/* <p className="text-sm">{t("products.text")}</p> */}
            </div>
            <div className="info pt-2">
              {/* button for opeing or closing filters */}
              {/* { slug === 'metrazhnye-kovry' ? (<SecondaryButton
                onClick={() =>
                  this.setState({
                    filterShow: !this.state.filterShow,
                    openfilter: !this.state.openfilter,
                  })
                }
                value={
                  this.state.openfilter
                    ? t("FiltersClose.text")
                    : t("FiltersOpen.text")
                }
              />):<div
               style={{marginBottom: "5vh"}}
              ></div>}
              <h1 className="text-lg title ">{this.state[this.name]}</h1> */}
              { slug === 'metrazhnye-kovry' ? (
              <div className="d-flex align-items-center gap-5 pointer"
                onClick={() =>
                  this.setState({
                    filterShow: !this.state.filterShow,
                    openfilter: !this.state.openfilter,
                  })
                }
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M1.83325 4.16699H18.4999" stroke={this.state.openfilter ? `#0942C3` : "black"} strokeWidth="1.4"/>
                  <path d="M1.83325 8.25293H15.8117" stroke={this.state.openfilter ? `#0942C3` : "black"} strokeWidth="1.4"/>
                  <path d="M1.83325 12.3389H12.5859" stroke={this.state.openfilter ? `#0942C3` : "black"} strokeWidth="1.4"/>
                  <path d="M1.83325 16.4248H9.36013" stroke={this.state.openfilter ? `#0942C3` : "black"} strokeWidth="1.4"/>
                </svg>
                <p style={{ color: this.state.openfilter ? "#0942C3" : "black", margin: '0 7px' }}>{t("filter.text")}</p>
               {this.state.openfilter &&( <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <path d="M1 1L9 9" stroke="#0942C3"/>
                  <path d="M1 9L9 1" stroke="#0942C3"/>
                </svg>)}
              </div>):<div
               style={{marginBottom: "5vh"}}
              ></div>}
              <div className="additional-filter">
                <span 
                  className="pointer"
                  style={{textDecoration: this.parsed['sort-by'] === 'all' ? 'underline' : '',
                    textUnderlineOffset: '8px',
                  }}
                  onClick={() => this.handleSortChange("all")}
                >
                  {t("sortAll.text")}
                </span>
                <span 
                  className="pointer"
                  style={{textDecoration: this.parsed['sort-by'] === 'isNew' ? 'underline' : '',
                    textUnderlineOffset: '8px',
                  }}
                  onClick={() => this.handleSortChange("isNew")}
                >
                    {t("sortNew.text")}
                </span>
                <span 
                  className="pointer"
                  style={{textDecoration: this.parsed['sort-by'] === 'isTrend' ? 'underline' : '',
                    textUnderlineOffset: '8px',
                  }}
                  onClick={() => this.handleSortChange("isTrend")}
                >
                 {t("sortTrend.text")}
                </span>
                <span 
                  className="pointer"
                  style={{textDecoration: this.parsed['sort-by'] === 'discount' ? 'underline' : '',
                    textUnderlineOffset: '8px',
                  }}
                  onClick={() => this.handleSortChange("discount")}
                >
                  {t("sortDiscount.text")}
                </span>
              </div>
            </div>

            <div className="collections-container">
              {/* child component which shows filters */}
             { slug === 'metrazhnye-kovry' && ( <SideBar
                filterShow={this.state.filterShow}
                button = {this.state.button}
                filterToggle={this.filterToggle}
                CloseFilter = {this.CloseFilter}
              />)}
              {/* if filters didn't match any element notFound text will show */}
              {this.state.notFound ? (
                <h3>{t("notFound.text")}</h3>
              ) : (
                <div
                  className={` pt-2 ${
                    (this.state.filterShow || slug !== 'metrazhnye-kovry') ? "collections" : "collections-small"
                  } `}
                >
                  {/* Skeleton for product card */}

                  {this.state.isLoading && (
                    <ProductCardSkeleton cards={this.state.limit} />
                  )}
                  {/* iterating category elements by ProductCard component */}
                  {this.state.product.map((col, index) => (
                    <Link key={col.id} to={`/product/${col.slug}`}>
                      <ProductCard
                        name={
                          col.collection.nameUz + " " + col.name.slice(2, 6)
                        }
                        articul={"#" + col.name}
                        index = {index}
                        price={col.price}
                        image={col.image ? col.image : col.collection.image}
                        isTrend={col.isTrend}
                        isNew={col.isNew}
                        isDiscount={col.discount}
                        catName={this.state[this.name]}
                      />
                    </Link>
                  ))}
                </div>
              )}
            </div>
            {/* Pagination  */}
            <Paginate
              limit={limit}
              total={total}
              offset={this.offset}
              handlePageChange={this.handlePageChange}
            />
          </div>
        </Suspense>
      </div>
    );
  }
}
export default withRouter(withTranslation()(Collection));
