import axios from "axios";
import React from "react";
import Cookies from "universal-cookie";

import { withRouter } from "react-router";
import { BaseUrl } from "../common/api";
import Mapleaflet from "../common/mapLeaflet";
import { withTranslation } from "react-i18next";

const cookies = new Cookies();
// page where shows all shops' informations with leaflet
class Contacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      regions: [],
      positions: [],
      reg: "",
      center: [],
      show: [],
    };
    this._isMounted = false;
    this.positions = [];
    this.pos = {};
    this.center = [];
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "name_uz";
      this.regname = "nameUz";
      this.address = "address_uz";
    } else {
      this.name = "name_ru";
      this.regname = "nameRu";
      this.address = "address_ru";
    }
  }
  // fetching all data in regions/ api
  async componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    const schema = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "SAG ковры",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "улица Толмачёва, 22",
          "addressRegion": "Свердловская область",
          "addressLocality": "Екатеринбург",
          "addressCountry": "RU",
          "postalCode": "620000",
      },
      "image": "указать ссылку на логотип компании",
      "email": "info.ru@sag.uz",
      "telephone": "+79220303495",
      "openingHours":["Mo 09:00-20:00","Tu 09:00-20:00","We 09:00-20:00","Th 09:00-20:00","Fr 09:00-20:00","Sa 09:00-20:00","Su 09:00-20:00"],
      "openingHoursSpecification": [
          {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Monday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
  ,        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Tuesday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
  ,        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Wednesday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
  ,        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Thursday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
  ,        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Friday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
  ,        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Saturday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
  ,        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "https://schema.org/Sunday",
          "opens": "09:00:00",
          "closes": "20:00:00"
          }
      ]
  
    }

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(schema);
    document.head.appendChild(script);
    
    this._isMounted = true;
    await axios
      .get(BaseUrl + "regions/")
      .then((res) => {
        let regions = res.data;
        regions = regions.filter((el) => el.isActive === true);
        this._isMounted &&
          this.setState({
            regions: regions,
          });
      })
      .catch((err) => {
        console.log(err);
        console.log(err.message);
        console.log(err.response);
      });
    this.state.regions.map((region) =>
      region.shops.map((shop) => {
        this.pos["lng"] = shop.lng;
        this.pos["lat"] = shop.lat;
        this.positions.push(this.pos);
        this.pos = {};
        return this.positions;
      })
    );
    const reg = cookies.get("Region");

    this.reg_id = this.state.regions.find((item) => item.id === parseInt(reg));

    // gets first shops location in order to give center for leaflet

    if (this.reg_id.shops.length > 0) {
      this.center.push(this.reg_id.shops[0].lng);
      this.center.push(this.reg_id.shops[0].lat);
    }
    this._isMounted &&
      this.setState({
        positions: this.positions,
        center: this.center,
      });
  }
  // formating phone
  phoneNumberFormat = (num) => {
    return num.replace(
      /(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/,
      " $1 ($2)-$3-$4-$5"
    );
  };
  // shows modal which holds an informations releted to shops
  showModal = (id) => {
    this.show = this.state.show;
    if (this.show.includes(id)) {
      this.show.splice(this.show.indexOf(id), 1);
    } else {
      this.show.push(id);
    }
    this._isMounted &&
      this.setState({
        show: this.show,
      });
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;
    return (
      <div className="main">
        <div className="container">
          <h1 className="text-title"> {t("ourAddress.text")}</h1>
          <div className="addresses">
            {/* iterating regions' information */}
            {this.state.regions
              .filter((el) => el.shops.length > 0)
              .map((region) => (
                <div
                  key={region.id}
                  className="collapsible"
                  onClick={() => this.showModal(region.id)}
                >
                  <div className="contact-card">
                    <div className="contact-inform">
                      <div className="contact-image">
                        <img src={region.image} alt={region.nameUz} />
                      </div>
                      <div className="ml-2">
                        <strong className="tex-bold text-lg">
                          {region[this.regname]}
                        </strong>
                        <p className="text">
                          {t("allShops.text")}: {region.shops.length}
                        </p>
                        <svg 
                          className={`icon-arrow ${this.state.show.includes(region.id) ? "rotate" : ""}`} 
                          xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" fill="none">
                          <path d="M1 1L8 8L15 1" stroke="#01091C"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                  {/* must be closed */}
                  {/* {showing the modal } */}
                  {this.state.show.includes(region.id) && (
                    <div className="contact-content mt-3 p-2">
                      {region.shops.map((shop) => (
                        <div key={shop.id}>
                          <div className="seperator"></div>
                          <div className="shop-container">
                            <h2>
                              <strong className="text text-bold">
                                {shop[this.name]}
                              </strong>
                            </h2>
                            <h5>
                              <strong className="text-sm text-bold">
                                📍{shop[this.address]}
                              </strong>
                            </h5>
                            <br />
                            {shop.extra_phone && (
                              <div className="contact-phone ml-2 py-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none"><path d="M2.56417 6.14292C3.58417 8.1475 5.2275 9.79083 7.23208 10.8108L8.79042 9.2525C8.98875 9.05417 9.265 8.9975 9.51292 9.07542C10.3062 9.3375 11.1562 9.47917 12.0417 9.47917C12.2295 9.47917 12.4097 9.55379 12.5425 9.68663C12.6754 9.81947 12.75 9.99964 12.75 10.1875V12.6667C12.75 12.8545 12.6754 13.0347 12.5425 13.1675C12.4097 13.3004 12.2295 13.375 12.0417 13.375C8.84802 13.375 5.78517 12.1063 3.52692 9.84808C1.26867 7.58983 0 4.52698 0 1.33333C0 1.14547 0.0746277 0.965304 0.207466 0.832466C0.340304 0.699628 0.520472 0.625 0.708333 0.625H3.1875C3.37536 0.625 3.55553 0.699628 3.68837 0.832466C3.82121 0.965304 3.89583 1.14547 3.89583 1.33333C3.89583 2.21875 4.0375 3.06875 4.29958 3.86208C4.3775 4.11 4.32083 4.38625 4.1225 4.58458L2.56417 6.14292Z" fill="#01091C"></path></svg>
                                <span className="ml-1">
                                  {" "}
                                  {this.phoneNumberFormat(shop.extra_phone)}
                                </span>
                              </div>
                            )}
                            {shop.main_phone && (
                              <div className="contact-phone ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none"><path d="M2.56417 6.14292C3.58417 8.1475 5.2275 9.79083 7.23208 10.8108L8.79042 9.2525C8.98875 9.05417 9.265 8.9975 9.51292 9.07542C10.3062 9.3375 11.1562 9.47917 12.0417 9.47917C12.2295 9.47917 12.4097 9.55379 12.5425 9.68663C12.6754 9.81947 12.75 9.99964 12.75 10.1875V12.6667C12.75 12.8545 12.6754 13.0347 12.5425 13.1675C12.4097 13.3004 12.2295 13.375 12.0417 13.375C8.84802 13.375 5.78517 12.1063 3.52692 9.84808C1.26867 7.58983 0 4.52698 0 1.33333C0 1.14547 0.0746277 0.965304 0.207466 0.832466C0.340304 0.699628 0.520472 0.625 0.708333 0.625H3.1875C3.37536 0.625 3.55553 0.699628 3.68837 0.832466C3.82121 0.965304 3.89583 1.14547 3.89583 1.33333C3.89583 2.21875 4.0375 3.06875 4.29958 3.86208C4.3775 4.11 4.32083 4.38625 4.1225 4.58458L2.56417 6.14292Z" fill="#01091C"></path></svg>
                                <span className="ml-1">
                                  {" "}
                                  {this.phoneNumberFormat(shop.main_phone)}
                                </span>
                              </div>
                            )}
                            <div className="py-1">
                              <img src={shop.image} alt={shop.name_uz} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div>
            <h1 className="text-title"> {t("mapAddress.text")}</h1>
           
            <Mapleaflet
              positions={this.state.positions}
              center={this.state.center}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Contacts));
