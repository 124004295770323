import React from "react";
import img from "../assets/static/img/13.png";
import img_1 from "../assets/static/img/10.png";
import img_2 from "../assets/static/img/1.png";
import img_3 from "../assets/static/img/2.png";
import img_4 from "../assets/static/img/3.png";
import img_5 from "../assets/static/img/4.png";
import img_6 from "../assets/static/img/5.png";
import img_7 from "../assets/static/img/14.png";
import img_8 from "../assets/static/img/15.png";
import img_9 from "../assets/static/img/16.png";
import img_10 from "../assets/static/img/12.png";
import img_11 from "../assets/static/img/Method1.png";
import img_12 from "../assets/static/img/9.png";
import img_13 from "../assets/static/img/image 12.jpg";
import img_15 from "../assets/static/img/19.png";
import img_16 from "../assets/static/img/20.png";
import img_17 from "../assets/static/img/21.png";
import img_18 from "../assets/static/img/22.png";
import img_19 from "../assets/static/img/17.png";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class MethodPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div className="main" style={{ marginTop: "32px" }}>
        <div className="container image-text-section">
          <img src={img} style={{ width: "100%" }} alt="sag" />
          <h2 className="bold mission-text">SAG method</h2>
          <p style={{ width: "80%", display: "inline-block" }}>
           {t("sagMethod.text")}
          </p>
        </div>
        <div className="container about-section-container method-section-container">
          <div className="about-section">
            <div className="image-container">
              <img src={img_1} alt="Building Image" />
            </div>
            <div className="text-container">
              <h2>SAG orginal fiber</h2>
              <p>
              {t("sagFiber.text")}
              </p>
              <img
                style={{ marginTop: "24px" }}
                src={img_19}
                alt="Building Image"
              />
            </div>
          </div>
        </div>
        <div className="container method-section-container">
          <div className="about-section two">
            <div className="text-container two">
              <h2>SAG cotton</h2>
              <p>
               {t("sagCotton.text")}
              </p>
              <img
                style={{ marginTop: "24px" }}
                src={img_13}
                alt="Building Image"
              />
            </div>
            <div className="image-container">
              <img src={img_7} alt="Building Image" />
            </div>
          </div>
        </div>
        <div className="container method-section-container">
          <div className="about-section">
            <div className="image-container">
              <img src={img_8} alt="Building Image" />
            </div>
            <div className="text-container">
              <h2>SAG polyester</h2>
              <p>
               {t("sagPolyester.text")}
              </p>
              <img
                style={{ marginTop: "24px" }}
                src={img_15}
                alt="Building Image"
              />
            </div>
          </div>
        </div>
        <div className="gallery-container">
          <div className="container">
            <div className="top-grid">
              <img src={img_3} alt="Thread Image 2" />
              <img src={img_4} alt="Thread Image 3" />
              <img src={img_5} alt="Thread Image 4" />
            </div>
            <div className="bottom-grid">
              <img src={img_6} alt="Thread Image 5" />
              <img src={img_2} alt="Thread Image 1" />
            </div>
          </div>
        </div>
        <div className="container method-section-container">
          <div className="about-section two">
            <div className="text-container two">
              <h2>SAG acrylic</h2>
              <p>
               {t("sagAkrill.text")}
              </p>
              <img
                style={{ marginTop: "24px" }}
                src={img_17}
                alt="Building Image"
              />
            </div>
            <div className="image-container">
              <img src={img_9} alt="Building Image" />
            </div>
          </div>
        </div>
        <div className="container method-section-container">
          <div className="about-section">
            <div className="image-container">
              <img src={img_10} alt="Building Image" />
            </div>
            <div className="text-container">
              <h2>SAG polipropilen</h2>
              <p>
               {t("sagPolypropylene.text")}
              </p>
              <img
                style={{ marginTop: "24px" }}
                src={img_16}
                alt="Building Image"
              />
            </div>
          </div>
        </div>
        <div className="container method-section-container">
          <div className="about-section two">
            <div className="text-container two">
              <h2>SAG eco fiber</h2>
              <p>
               {t("sagEcoFiber.text")}
              </p>
              <img
                style={{ marginTop: "24px" }}
                src={img_18}
                alt="Building Image"
              />
            </div>
            <div className="image-container">
              <img src={img_11} alt="Building Image" />
            </div>
          </div>
        </div>
        {/* <div
          className="container image-text-section"
          style={{ marginTop: "0" }}
        >
          <h2 className="bold mission-text" style={{ marginTop: "0" }}>
            SAG method
          </h2>
          <p style={{ width: "80%", display: "inline-block" }}>
            Tasodifan gilamni kuydirib qo`ygan bo`lsangiz-u, boshqasini hali
            beri sotib olishga qurbingiz yetmasa nima qilish kerak? O`ziyam, bu
            dog`, xonani qaysi burchagida o`tirmang ko`zingizga yarq etib
            tashlanaveradi-da! Undan qutulish uchun sizga zargar sabri va mergan
            aniqligi kerak bo`ladi. Shuningdek, mebel pichog’i va suvga chidamli
            yelim, kichkina qaychi hamda, ehtiyojingiz bo`lsa, ko`zoynak bilan
            qurollaning. Avvalambor, kuygan patlarni astagina, uning asosiga
            putur yetkazmay kesib olishingizga to`g`ri
          </p>
        </div> */}
        <div className="container method-image-container">
          <img src={img_12} style={{ width: "100%" }} alt="sag" />
        </div>
      </div>
    );
  }
}
export default withRouter(withTranslation()(MethodPage));
