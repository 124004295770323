/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import he from "he";

import { withRouter } from "react-router-dom";

class BlogCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  removeTags = (content) => {
    content = he.unescape(content);
    const regex = /<[^<]+?>/gi;
    content = content.replace(regex, "");
    return content;
  };

  render() {
    return (
      <div
        className="blog-image pointer"
        onClick={() => this.props.history.push(`post/${this.props.slug}`)}
      >
        <img src={this.props.image} alt={this.props.nameUz} />
        <div className="blog-info">
          <div className="d-flex flex-column">
            <p style={{color: 'white'}}>{this.props.titleUz}</p>

            {/* {this.props.textUz && (
              <article className="text-sm mt-4 two-line-text">
                {" "}
                {this.removeTags(this.props.textUz)}
              </article>
            )} */}
          </div>
          {/* <div className="blog-date mb-2">
            <p className="mb-0">{this.props.createdAt.slice(0, 10)}</p>
          </div> */}
        </div>
      </div>
    );
  }
}
export default withRouter(BlogCard);
