import React from "react";
import { Link } from "react-router-dom";
import { BaseUrl } from "./api";
import { withTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import axios from "axios";
import { withRouter } from "react-router-dom";

const cookies = new Cookies();

class AppFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      social: [],
      contacts: [],
      data: [],
    };
    this._isMounted = false;

    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
    } else {
      this.name = "nameRu";
    }
  }
  async componentDidMount() {
    this._isMounted = true;
    await axios.get(BaseUrl + "footer/").then((res) => {
      const data = res.data;

      const social = data["socialMedia"];
      const contacts = data["contacts"];
      const categories = data["categories"];
      this._isMounted &&
        this.setState({
          social,
          contacts,
          categories,
        });
    });
  }

  getCategory = (path) => {
    this.props.history.push(path);
  };
  getStatic = (id) => {
    this.props.history.push("/static/" + id);
  };
  phoneNumberFormat = (num) => {
    return num.replace(
      /(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/,
      " $1 ($2) $3-$4-$5"
    );
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;

    return (
      <footer>
        <div className="container">
          <section>
              <h1 className="text-lg">{t("Category.text")}</h1>
              <ul>
                {this.state.categories.map((category) => (
                  <li
                    key={category.id}
                    className="text-sm pointer"
                    onClick={() => {
                      this.getCategory(
                        `/category/${category.slug}/filter/?offset=0`
                      );
                    }}
                  >
                    {category[this.name]}
                  </li>
                ))}
              </ul>
          </section>
          <section>
            <h1 className="text-lg">{t("Information.text")}</h1>
            <ul>
              <li className="text-sm pointer">
                <Link to="/about">{t("AboutUs.text")}</Link>
              </li>
              <li className="text-sm">
                <Link to="/contacts">{t("Addresses.text")}</Link>
              </li>
              <li className="text-sm">
                <Link to="/blogs">{t("Blogs.text")}</Link>
              </li>
            </ul>
          </section>
          <section>
            <h1 className="text-lg">{t("Phone.text")}</h1>

            <ul>
              <li className="text-sm">
                {this.state.contacts[0] && (
                  <a href={"tel:" + this.state.contacts[0].main_phone}>
                    {this.phoneNumberFormat(this.state.contacts[0].main_phone)}
                  </a>
                )}
              </li>
              <li className="text-sm mt-1">
                {this.state.contacts[0] && (
                  <a
                    href={"tel:" + this.state.contacts[0].extra_phone}
                    className="text-nowrap"
                  >
                    {this.phoneNumberFormat(this.state.contacts[0].extra_phone)}
                  </a>
                )}
              </li>
              {/* <li>
                <h1 className="text-lg">{t("Email.text")}</h1>
                <p className="text-sm mt-1">
                  {this.state.contacts[0] && this.state.contacts[0].email}
                </p>
              </li> */}
            </ul>
          </section>
          <section>
            {/* <h1 className="text-lg">{t("FollowUs.text")}</h1> */}
            <div className="links">
              {this.state.social.map((item) => (
                <a href={item.link} key={item.id}>
                  <img
                    src={item.image}
                    alt={item.name}
                    width="18"
                    length="18"
                    className="icon"
                  />
                </a>
              ))}
            </div>
          </section>
        </div>
      </footer>
    );
  }
}
export default withRouter(withTranslation()(AppFooter));
